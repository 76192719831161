import React from "react"
import bangkokmagique from "../assets/thai/bangkok-magique.webm"
import thainumber from "../assets/thai/Thai-numbers.mp4"
import numberthai from "../assets/thai/thai-numbers-1-10.webm"

export default function Thai() {
  return (
    <div>
      <video controls width="30%">
        <source src={bangkokmagique} type="video/webm" />
      </video>
      <video controls width="30%">
        <source src={thainumber} type="video/mp4" />
      </video>
      <video controls width="30%">
        <source src={numberthai} type="video/webm" />
      </video>
    </div>
  )
}
